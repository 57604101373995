import request from "utils/request";

export const routeGetDriverPosition = async (id, date) =>
  (
    await request({
      url: `/routes/${id}/locations`,
      method: "get",
      params: { date },
    })
  ).data.data[0];

export const routeReportPosition = (id, position) =>
  request({ url: `/routes/${id}/locations`, method: "post", data: position });
