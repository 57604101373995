import { forwardRef, useState } from "react";
import {
  Button,
  FormControl,
  Row,
  Col,
  Dropdown,
  Badge,
} from "react-bootstrap";
import { formatTime } from "utils/helpers";
import Icon from "components/icon";

export const Note = ({ note, updateNote, deleteNote, notEditable }) => {
  const [editing, setEditing] = useState(false);
  const [editingNote, setEditingNote] = useState(note?.note || "");

  if (!note) {
    return null;
  }
  return (
    <div className={"container-fluid"} id="note">
      <div className={"row p-4 border-top"}>
        <div className={""} style={{ maxWidth: "7rem" }}>
          <img
            className={"w-100 rounded-circle"}
            src={note.poster.image || note.poster.profileImage}
            alt=""
          />
        </div>
        <div className={"col"}>
          <div>
            <span>{note.poster.fullName}</span>
            <br />
            <div className={"small"}>
              {note.createdAt !== note.updatedAt
                ? "Edited: " + formatTime(note.updatedAt)
                : formatTime(note.createdAt)}
            </div>
            <div className={"badge bg-secondary"}>{note.category.label}</div>
          </div>

          <div>
            {editing && updateNote ? (
              <>
                <FormControl
                  as="textarea"
                  rows={2}
                  value={editingNote}
                  onChange={(e) => setEditingNote(e.target.value)}
                />
                <div style={{ marginTop: 12 }}>
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={async () => {
                      setEditing(false);
                      await updateNote({ ...note, note: editingNote });
                    }}
                  >
                    Update
                  </Button>
                  <Button variant="link" onClick={() => setEditing(false)}>
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <div style={{ whiteSpace: "break-spaces" }}>{note.note}</div>
            )}
          </div>
        </div>
        <div className="col-1">
          {!editing && !notEditable && (
            <Dropdown>
              <Dropdown.Toggle
                as={forwardRef(({ onClick }, ref) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 25,
                      backgroundColor: "white",
                      border: "1px solid #dee2e6",
                      padding: 4,
                    }}
                    onClick={onClick}
                    ref={ref}
                  >
                    <Icon type="ellipsis" />
                  </div>
                ))}
              />
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setEditing(true)}>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => deleteNote(note)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};

const NotesDisplay = ({ notes, updateNote, deleteNote }) => (
  <div className={"bg-light"}>
    {notes.map((note) => (
      <Note
        key={note.id}
        note={note}
        updateNote={updateNote}
        deleteNote={deleteNote}
      />
    ))}
  </div>
);

export default NotesDisplay;
