import React, { useState, useEffect } from "react";
import { startOf } from "utils/dates";
import { routeReportPosition } from "api/routes";
import DriverLayout from "layouts/driver";
import Pickups from "./driver-components/pickups";
import SinglePickup from "./driver-components/pickup-single";
import { useWindowDimensions } from "utils/helpers";
import { useSearchParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const DriverRoute = ({
  pickupRouteData,
  setDateDriver,
  pickups: pickupsAll,
  common,
}) => {
  const { width } = useWindowDimensions();
  const [currentPickup, setCurrentPickup] = useState(null);
  const [pickups, setPickups] = useState([]);
  const [pickupsDone, setPickupsDone] = useState([]);
  const [pickupsUpcoming, setPickupsUpcoming] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setDateDriver(
      pickupRouteData.pickupDate || searchParams.get("date") || new Date()
    );
  }, []);

  useEffect(() => {
    setPickups(pickupsAll);

    const prePickupsUpcoming = [];
    const prePickupsDone = [];
    if (pickupsAll.length) {
      pickupsAll.map((p) => {
        const pickupStatus = common.pickupStatuses[p.statusId];
        const pickupType = common.pickupTypes[p.pickupTypeId];
        const additionalPickupData = { pickupStatus, pickupType };
        if (pickupStatus.label === "Pending") {
          prePickupsUpcoming.push({ ...p, ...additionalPickupData });
        } else {
          prePickupsDone.push({ ...p, ...additionalPickupData });
        }
      });
    }
    setPickupsUpcoming(prePickupsUpcoming);
    setPickupsDone(prePickupsDone);

    if (width > 800 && pickupsAll.length > 0 && !currentPickup) {
      setCurrentPickup(prePickupsUpcoming[0] || prePickupsDone[0]);
    }
  }, [pickupsAll]);

  useEffect(() => {
    let lastFoundPos;
    navigator.geolocation.watchPosition((position) => {
      lastFoundPos = position.coords;
    });

    const reportingInterval = setInterval(() => {
      if (pickupRouteData.pickupRoutes.length !== 0 && lastFoundPos)
        Promise.all(
          pickupRouteData.pickupRoutes.map((route) =>
            routeReportPosition(route.id, {
              lat: lastFoundPos.latitude,
              lng: lastFoundPos.longitude,
            })
          )
        );
    }, Number(process.env.REACT_APP_DRIVER_POSITION_REPORT_INTERVAL) * 100);
    return () => {
      clearInterval(reportingInterval);
    };
  }, [pickupRouteData]);

  const createNumeric = (done, upcoming, currentId) => {
    if (!!currentId) {
      const upcomingIndex = upcoming.findIndex((item) => currentId === item.id);
      if (upcomingIndex >= 0) {
        return { current: upcomingIndex + 1, all: upcoming.length };
      }
      const doneIndex = done.findIndex((item) => currentId === item.id);
      if (doneIndex >= 0) {
        return { current: doneIndex + 1, all: done.length };
      }
    }
    return { current: 0, all: 0 };
  };

  const { isFetching, pickupsLoaded } = pickupRouteData || {};

  return (
    <DriverLayout>
      <Row className="flex-fill g-0">
        <Col sm={0} xs={"auto"}>
          <Pickups
            date={startOf(pickupRouteData.pickupDate, "day")}
            onChange={setDateDriver}
            loadingStatus={isFetching || !pickupsLoaded}
            pickups={pickups}
            selectedPickup={currentPickup}
            onSelectPickup={(pickup) => {
              setCurrentPickup(pickup);
            }}
            pickupsDone={pickupsDone}
            pickupsUpcoming={pickupsUpcoming}
          />
        </Col>
        {currentPickup && (
          <Col>
            <SinglePickup
              numeric={createNumeric(
                pickupsDone,
                pickupsUpcoming,
                currentPickup?.id
              )}
              pickup={currentPickup}
              setCurrentPickup={(pickup) => {
                setCurrentPickup(pickup);
              }}
              common={common}
            />
          </Col>
        )}
      </Row>
    </DriverLayout>
  );
};

export default DriverRoute;
